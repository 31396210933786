/* Login page logo */

.logo-login-light {
    height: 34px;
    width: 166px;
}

.dashboard-logo {
    padding-left: 20px !important;
    position: relative;
    display: grid;
    gap: 5px;
    grid-template-columns: 3fr 1fr;
}
.toggle-icon {
    margin-top: 5px;
}

.toggled-logo {
    height: 28px;
    width: 27px;
}
.dropzone-custom-multiple {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1em;
}
.dropzone-custom-single {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 1em;
}
.inputLabelWithFiles-custom {
    position: relative;
    margin: 1em 1em 1em 1em;
    height: 170px;
    width: 170px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.555) !important;
    opacity: 0;
    animation: forwards fadeIn 1s;
    animation-delay: 1s;
    cursor: pointer;
    box-shadow: $common-box-shadow;
}
.preview-image {
    position: relative;
    height: 170px;
    width: 170px;
    border-radius: 10px;
    margin: 1em;
    animation: forwards zoomIn 1s;
    cursor: pointer;
    img {
        border-radius: 10px;
    }
}

.close-icon {
    position: absolute;
    color: rgb(236, 81, 81);
    border-radius: 100%;
    background-color: $white;
    right: 0;
    transform: translate(5px, -10px);
    &:hover {
        border: 2px solid rgb(230, 136, 136);
    }
}

.add-image-icon {
    height: 170px;
    width: 170px;
    border-radius: 10px;
    color: $primary-color;
}
.header-wrapper {
    padding: 2.4em;
}
.noPadding {
    padding: 0 !important;
}
.noMargin {
    margin: 0 i !important;
}


// step form header
.stepForm-header {
    position: unset;
    display: flex;
    height: 32px;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 5em;
}
.stepForm-header-icon {
    position: unset;
    display: block;
    border: 2px solid gray;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-active {
        position: unset;
        display: block;
        border: 2px solid rgb(248, 192, 154);
        height: 32px;
        width: 32px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

     &-checked {
    position: unset;
    display: block;
    border: 2px solid rgb(12, 212, 12);
    height: 32px;
    width: 32px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
     }
}

.stepForm-header-line {
    display: block;
    width: 250px;
    height: 4px;
    border: 3px solid gra;
    background-color: gray;
    &:last-child {
        display: none;
    }
    &-checked {
        display: block;
        width: 250px;
        height: 4px;
        border: 3px solid green;
        background-color: green;
        &:last-child {
            display: none;
        }
    }
}

.check-icon {
    height: 24px;
    width: 24px;
    color: green;
}

.step-name {
    position: absolute;
    top: 133px;
    margin-left: -2%;
    color: gray;
    &-active {
        position: absolute;
    top: 133px;
    margin-left: -2%;
        color: $primary-color;
    }
}

.p-us {
    position: unset;
}

.circle {
    display: block;
    background-color: $primary-color;
    border-radius: 100%;
    height: 0.5em;
    width: 0.5em;
}


.ant-cascader-menu{
    min-width: 250px !important;
}

.no-border{
    border-radius: 0!important;
}

.icon-circle{
    border: 1px solid $primary-color;
    padding: 10px 11px;
    border-radius: 20px;

}

button:hover{
    .icon-circle{
        border-color:$white;
        background: $primary-color;
        color: $white
    }
}

.active-form{
    border: 1px solid #2f2f2f;
    box-shadow: 0px 1px 1px #2f2f2f;

}

.bg-gray{
    background: #AAA;
}

// WYSIWYG EDITOR CONFIGS

.rdw-editor{
    &-wrapper{
        border: 1px solid #efefef;
    }

    &-main {
        min-height: 180px;
    }
}

.no-padding-circle{
    padding: 5px 10px !important;
    border-radius: 40% !important;
}

.btn-sq-effect{
    padding: 10px 15px;
}



// role checkbox style
.role-checkbox.checkbox label::before {
    border-color: #2f2f2f !important;
}