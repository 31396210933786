.searchProduct{
    position: relative;

    // .searchInput:hover{
    //     .searchDropDown{
    //         opacity: 1;
    //         visibility:visible;
    //     }
    // }

    .searchDropDown{
        position: absolute;
        font-family: 'Poppins',sans-serif;
        height: 150px;
        overflow-y: scroll;
        border-radius: 5px;
        width: 95%;
        left:15px;
        top:35px;
        background-color: #fff !important;
        border: 0.001px solid gainsboro;
        box-shadow: 2px 2px solid rgb(230, 229, 229) !important;
        opacity: 1;
        visibility: visible;
        // display: none;
        .singleOpt{
          &:hover{
            color: rgb(39, 39, 136);
            cursor: pointer;

          }
        }
    }
}